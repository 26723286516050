.container-Editdata::-webkit-scrollbar {
    display: none;
}
.container-Editdata{
    position: relative;
    height: 92vh;
    overflow: auto;
    margin: 17px 13px;
    width: 72vw;
    padding: 0px 12px;
}
.display-video{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.btn-editvideo Button{
    font-weight: 700;
    background-color: var(--themecolor);
    color: white;
    font-size: 12px;
    padding: 2px 22px;
    position: absolute;
    right:3%;
}
.btn-editvideo Button:hover{
    font-weight: 700;
    background-color: var(--themecolor);
    color: white;
    font-size: 12px;
    padding: 2px 22px;
}
.btn-update Button{
    position: absolute;
    right:0%;
    background-color: var(--themecolor);
    font-weight: 800;
    text-align: end;
}
.btn-update Button:hover{
    position: absolute;
    right:0%;
    background-color: var(--themecolor);
    font-weight: 800;
    text-align: end;
}

@media (max-width:900px) {
    .container-Editdata {
        height: 92vh;
        overflow: auto;
        margin: 17px 1rem;
        width: 87vw;
    }
}
@media (max-width:349px) {
    .btn-editvideo Button {
        font-weight: 700;
        background-color: var(--themecolor);
        color: white;
        font-size: 10px;
        padding: 2px 18px;
    }
    .display-video h2{
        flex-direction: column;
        align-items: start;
    }
    .display-video button{
      margin-bottom: 12px;
    }
}
