.div-table {
  padding: 20px;
}
.head-div {
  border: 2px solid yellow;
}
.tablecontainer {
  margin-top: 10px;
}
.table-cell {
  background-color: #6b59cc;
}
.tablecontainer::-webkit-scrollbar {
  display: none;
}
.container-analytics {
  height: 89vh;
  overflow-y: auto;
}
.container-analytics::-webkit-scrollbar {
  display: none;
}
.container-chatReport {
  height: 79vh;
  overflow-y: auto;
}
.container-chatReport::-webkit-scrollbar {
  display: none;
}
.heading-chatreport h4 {
  font-size: 22px;
  font-weight: 800;
}
.createUserBtn {
  background-color: #6b59cc;
  font-weight: 800 !important;
}
.createUserBtn:hover {
  background-color: #6b59cc;
  font-weight: 800;
}
.ghostusercontainer {
  display: flex;
  justify-content: space-between;
}
.table-w{
  width: 88vw!important; 
}
.gridPadding {
  padding: 32px 12px 32px 37px;
}
@media (min-width:900px) and (max-width:1200px) {
  .table-w {
    width: 84vw!important;
}
}

@media (min-width:426px) and (max-width:899px) {
  .table-w {
    width: 92.6vw!important;
    margin-top: 2rem;
}
}
@media (min-width: 320px) and (max-width: 425px) {
  .tablecontainer {
    width: auto;
  }
  .table-cell {
    width: 20px;
  }
}

@media (max-width: 507px) {
  .ghostusercontainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
@media (max-width: 793px) {
  .table-cell {
    background-color: #6b59cc;
    font-size: 15px !important;
  }
  .table-celltr {
    font-size: 15px !important;
  }
}