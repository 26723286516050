.box-form {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border-radius: 17px !important;
  padding: 6rem 3rem !important;
  width: 450px;
}
.box-form-signup {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border-radius: 17px !important;
  padding: 6rem 3rem !important;
  width: 450px;
}
.bg-btncolor Button {
  background-color: var(--themecolor);
  font-size: 15px;
  font-weight: bold;
}
.bg-btncolor Button:hover {
  background-color: var(--themecolor);
  font-size: 15px;
  font-weight: bold;
}

@media (min-width: 320) {
  .heading-form {
    font-size: 16px !important ;
  }

  .sub-heading-form {
    font-size: 14px !important;
  }

  .errorMessage {
    font-size: 12px !important;
  }
}
.heading-form {
  font-size: 24px;
}

.sub-heading-form {
  font-size: 16px;
}

.errorMessage {
  font-size: 14px;
}
.text-field {
  margin: 10px 0px;
}

@media (min-width: 320px) and (max-width: 425px) {
  .card-stack-heading h4 {
    font-size: 19.0999px;
  }
  .login-heading-stack h4 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .g-recaptcha {
    transform: scale(0.67);
    transform-origin: 0 0;
  }
  .box-form {
    width: 62%;
  }
  .box-form-signup {
    width: 70%;
    padding: 6rem 1.5rem !important;
  }
}

@media (min-width: 450px) and (max-width: 423px) {
  .margin-box {
    width: 266px;
  }
}
@media (min-width: 410px) and (max-width: 422px) {
  .margin-box {
    width: 250px;
    margin-left: 0.6rem;
  }
}

@media (min-width: 338px) and (max-width: 409px) {
  .margin-box {
    width: 250px;
    margin-left: -1rem;
  }
}

@media (min-width: 320px) and (max-width: 337px) {
  .margin-box {
    width: 245px;
    margin-left: -1.3rem;
  }
}


@media (min-width:320px) and (max-width:387px) {
  .btn-sgn{

    width: 98px !important;
    margin-left: -22px  !important;
  }
}
