.display-tabs {
  display: flex;
}
.sidenav-icon {
  border-radius: 12px;
  background: #edecf6;
  padding: 42px;
  margin: 23px 39px;
  color: blueviolet;
}
.active-tab {
  background-color: red;
}
.height-setting{
  height: 94.78vh
}
.height-conset{
 height: 91vh;
}
.tab-content {
  padding: 1rem 0rem;
}
.container-events-data {
  position: relative;
}
.btnprenxt {
  display: flex;
  justify-content: space-between;
  padding: 0px 25px 0px 25px;
}
.btn-prev {
  background-color: red;
}
.btnprenxt button {
  background-color: #6b59cc;
  font-weight: 800;
}

.btnprenxt button:hover {
  background-color: #6b59cc;
}
.tab-content Tabs:active {
  background-color: red;
}
.heading-header h2 {
  font-weight: 550;
  font-size: 22px;
  padding: 11px 0px 13px 32px;
}
.tabspanel {
  width: 15vw;
}
.pers2{
  margin-left: -3rem!important;
    margin-right: 17px!important;
}
.heading-toggletxt {
  font-size: 18px;
}
.btn-toggle {
  background: #8600d3;
}

.container-toggleLive {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0px 0px 0px;
}
.btn-toggle-others {
  margin: 0rem 2rem 0rem 5rem;
}
.heading-events h4 {
  font-size: 20px;
  font-weight: 700;
  text-align: start;
}
.ques-field {
  margin: 0px 0px 12px 0px;
  width: 100%;
}
.container-addQue {
  margin: 0px 0px 12px 0px;
}
.btn-Addque {
  text-align: right;
  margin: 18px 0rem -1rem 0rem;
}
.btn-Addque button {
  background: #6b59cc;
  font-weight: 700;
}
.btn-Addque button:hover {
  background: #6b59cc;
  font-weight: 700;
}
.button-container button {
  text-align: end;
}
.button-container h5 {
  text-align: start;
  font-size: 19px;
  font-weight: 546;
}
.container-others {
  display: flex;
  justify-content: space-between;
  padding: 18px 0px 0px 0px;
  width: 48vw;
}

.expanticon {
  border: 1px solid #6b59cc !important;
  border-radius: 12pc !important;
  font-size: 40px !important;
  text-align: end !important;
  color: #6b59cc !important;
}
.video-avtiveBtn Button {
  border: 2px solid green;
  border-radius: 7px;
  padding: 2px 30px;
  font-size: 10px;
  color: black;
  font-weight: 800;
}
.upload-button {
  background-color: #6b59cc;
  color: white;
  padding: 4px 9px;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 700;
}
.setting-tab-container {
  width: 90%;
}
.pers2{
  margin-right: 14px;
}
.pass2{
  margin-right: 14px;
}
@media (max-width: 1024px) {
  .container-others {
    display: flex;
    justify-content: space-between;
    padding: 18px 0px 0px 0px;
    width: 58vw;
  }
}
@media (max-width: 768px) {
  .tabspanel {
    width: 35vw;
  }
}
@media (max-width: 656px) {
  .tabspanel {
    width: 190px;
  }
}
@media (max-width: 475px) {
  .show-tabs {
    display: none;
  }

}
.jgugf {
  background-color: red;
}

@media (max-width: 610px) {
  .setting-tab-container {
    width: 100%;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 549px) {
  
  .tab-bar-event {
    width:100%!important;
    display:flex!important;
    justify-content: center!important;
  }
  .tab-bar-pass {
    width:100%!important;
    display:flex!important;
    justify-content: center!important;
 
  }
}
@media only screen and (min-device-width: 481px) {
  .tab-bar-event {
    width:100%!important;
    display:flex!important;
    justify-content: center!important;
  }
  .tab-bar-pass {
    width:100%!important;
    display:flex!important;
    justify-content: center!important;

  }
}

.display-tabs {
  display: flex;
}
.sidenav-icon {
  border-radius: 12px;
  background: #edecf6;
  padding: 42px;
  margin: 23px 39px;
  color: blueviolet;
}
.active-tab {
  background-color: red;
}

.tab-content {
  padding: 1rem 0rem;
}

.container-events-data {
  position: relative;
}
.btnprenxt {
  display: flex;
  justify-content: space-between;
  padding: 0px 25px 0px 25px;
}
.btn-prev {
  background-color: red;
}
.btnprenxt button {
  background-color: #6b59cc;
  font-weight: 800;
}

.btnprenxt button:hover {
  background-color: #6b59cc;
}
.tab-content Tabs:active {
  background-color: red;
}
.heading-header h2 {
  font-weight: 550;
  font-size: 22px;
  padding: 11px 0px 13px 32px;
}
.tabspanel {
  width: 15vw;
}

.heading-toggletxt {
  font-size: 18px;
}
.btn-toggle {
  background: #8600d3;
}
.container-toggleLive {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0px 0px 0px;
}
.btn-toggle-others {
  margin: 0rem 2rem 0rem 5rem;
}
.heading-events h4 {
  font-size: 20px;
  font-weight: 700;
  text-align: start;
}
.ques-field {
  margin: 0px 0px 12px 0px;

  width: 100%;
}
.container-addQue {
  margin: 0px 0px 12px 0px;
}
.btn-Addque {
  text-align: right;
  margin: 18px 0rem -1rem 0rem;
}
.btn-Addque button {
  background: #6b59cc;
  font-weight: 700;
}
.btn-Addque button:hover {
  background: #6b59cc;
  font-weight: 700;
}
.button-container button {
  text-align: end;
}
.button-container h5 {
  text-align: start;
  font-size: 19px;
  font-weight: 546;
}
.container-others {
  display: flex;
  justify-content: space-between;
  padding: 18px 0px 0px 0px;
  width: 48vw;
}

.expanticon {
  border: 1px solid #6b59cc !important;
  border-radius: 12pc !important;
  font-size: 40px !important;
  text-align: end !important;
  color: #6b59cc !important;
}
.video-avtiveBtn Button {
  border: 2px solid green;
  border-radius: 7px;
  padding: 2px 30px;
  font-size: 10px;
  color: black;
  font-weight: 800;
}
.upload-button {
  background-color: #6b59cc;
  color: white;
  padding: 4px 9px;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 700;
}
.setting-tab-container {
  width: 90%;
}
@media (max-width: 1024px) {
  .container-others {
    display: flex;
    justify-content: space-between;
    padding: 18px 0px 0px 0px;
    width: 58vw;
  }
}

@media (max-width: 768px) {
  .tabspanel {
    width: 35vw;
  }
}
@media (max-width: 656px) {
  .tabspanel {
    width: 190px;
  }
}
@media (max-width: 475px) {
  .show-tabs {
    display: none;
  }
}
.jgugf {
  background-color: red;
}

@media (max-width: 610px) {
  .setting-tab-container {
    width: 100%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .tab-bar-event {
    width:100%!important;
    display:flex!important;
    justify-content: center!important;
  }
  .tab-bar-pass {
    width:100%!important;
    display:flex!important;
    justify-content: center!important;

  }
}
@media only screen and (min-device-width: 481px) {
  .tab-bar-event {
    width:100%!important;
    display:flex!important;
    justify-content: center!important;
  }
  .tab-bar-pass {
    width:100%!important;
    display:flex!important;
    justify-content: center!important;
  }
}
@media (min-width:899px) and (max-width:1050px) {
  .pers2{
    margin-left: -11px!important;
    margin-right: 10px!important;
  }
  .pass1{
    margin-right: 13px;
    margin-left: 15px;
  }
  .setting-tab-container{
    width: 100%;
  }
}

@media  (min-width:109px) and (max-width:899px){
  .tab-bar-event{
    width:100%!important;
    margin:auto!important;
  }
  .tab-bar-pass{
    margin:auto!important;
  }
  .tabs-homeschange {
    padding-bottom: 3rem;
    border-bottom: 1px solid lightgray!important;
  } 
}
@media (max-width:1920px) {
  .height-setting {
    height: 90.78vh;
}

}
@media (max-width:412px) {
  .height-conset{
    height: auto;
   }
}