.container-analytics {
  height: 89vh;
  overflow-y: auto;
}
.container-analytics::-webkit-scrollbar {
  display: none;
}
.container-analytics h4 {
  font-weight: 600;
  font-size: 26px;
}
.downloadExc {
  border: 3px solid #6b59cc;
  border-radius: 30pc;
  padding: 7px;
  color: #6b59cc;
}
.tagUrl{
  overflow-wrap: break-word;
  line-height: 1.5;
}
.dfsfd{
  overflow-wrap: break-word;
  width: 36%;
}
.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  font-weight: 800!important;
  background-color: #6b59cc!important;
  color: white!important;
}
.showMoreBtn {
  display: none;
}
.formSelect {
  display: block !important;
}
.registrants-align{
  display: flex!important;
  align-items: center!important;
  flex-direction: row !important;
  justify-content: space-between!important;
}
.heading-registrant {
  width: 100% !important;
  font-weight: 500!important;
}
.searchReagistrant{
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  position: relative;

}
.downlodsheet{
  display: flex!important;
    align-items: center!important;
    justify-content: space-evenly!important;
    margin-top: 16px!important;
}
.gridInstruct{line-height: 1.7;
padding: 12px 3px 19px 13px;
background: #236cb714;

overflow-wrap: break-word;
border-radius: 2px;
}
.thankyoufont{
  font-size: 4rem;
}
.session-container{
  width: 91vw!important;
  margin-top: 2.5rem!important;
}
.maincon{
  padding:0px 20px
}
@media (min-width:1029px) and  (max-width:1294px) {
  .session-container{ 
    width: 89vw!important;


  }
}
@media (min-width:900px) and (max-width:1028px) {
  .session-container {
    width: 85vw!important;
}
}
@media (min-width:770px) and (max-width:899px) {
.session-container {
  width: 95vw!important;
}
}
@media (min-width: 734px) and (max-width:1373px) {
  .heading-registrant {
    width: 29% !important;
    
}
.downlodsheet{
  display: flex!important;
    align-items: center!important;
    justify-content: space-evenly!important;
    margin-top: 16px!important;
}
}
@media (min-width:200px) and (max-width: 733px) {
  .showMoreBtn {
    display: block;
  }
  .formSelect {
    display: none !important;
  }
  .maincon{
    padding:0px 14px
  }
  .registrants-align{

    flex-direction: column !important;
    justify-content: space-between!important;
  }
  .searchReagistrant{
   justify-content: start;

  }
}
@media (max-width:788px) {
  .thankyoufont{
    font-size: 2.7rem;
  }
}
@media (max-width:500px) {
  .thankyoufont{
    font-size: 2rem;
  }
}