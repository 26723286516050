.heading-home h2 {
  font-size: 30px;
}
.container-hometabs {
  display: flex;
}
.tabs-home {
  position: static;
  top: 0;
  /* border-right: 1px solid #80808085; */
  height: "91.4vh";
}
.tabs-align {
  margin: auto !important;
}
.tabs-align2 {
  width: 100%;
  margin: auto !important;
}
.btn-status Button {
  text-align: end;

  background-color: var(--themecolor);
  font-size: 13px;
  font-weight: bold;
}
.btn-status Button:hover {
  text-align: end;
  background-color: var(--themecolor);
  font-size: 13px;
  font-weight: bold;
}
.custom-h {
  height: 91vh;
}
.alignheading {
  padding: 0px 45px;
}
.btn-status Button {
  text-align: end;

  background-color: var(--themecolor);
  font-size: 13px;
  font-weight: bold;
}
.btn-status Button:hover {
  text-align: end;
  background-color: var(--themecolor);
  font-size: 13px;
  font-weight: bold;
}
.scroll-home::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  overflow-y: auto;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.tabs-align1 {
  margin-right: 14px !important;
  margin-left: 10px !important;
}
.tab-align2 {
  margin-right: 17px !important;
  margin-left: 20px !important;
}
.paraInstru {
  line-height: 1.7;
  padding: 12px 3px 19px 13px;
  background: #236cb714;
  margin: 23px 5px 0px 16px;
  overflow-wrap: break-word;
  border-radius: 2px;
  width: 55%;
}
.avatar1 {
  margin-left: -2px;
  margin-right: 19px !important;
}
.avatar2 {
  margin-left: -6px;
  margin-right: 15px !important;
}
@media (max-width: 550px) {
  .tab-align2 {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}
@media (max-width: 475px) {
  .btn-status {
    text-align: start;
    padding: 0px 19px 0px 0px;
  }
  .iconsmore {
    text-align: end;
  }
  .tabs-home {
    position: static;
    top: 0;
    border-right: none;
  }
}
@media only screen and (min-device-width: 120px) and (max-device-width: 549px) {
  .tabs-homes {
    height: auto;
    width: 96%;
    margin: auto;
  }
  .container-hometabs {
    display: flex;
    flex-direction: column;
    width: 98vw;
  }
  .heading-details {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  .tabs-home {
    width: 100%;
    max-height: 238px;
  }
}
.heading-details {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.scroll-home1 {
  margin-top: 1.2rem;
  padding: 2px 45px;
  word-break: break-word;
  background: #236cb714;
}
@media (min-width: 100px) and (max-width: 549px) {
  .avatar1 {
    margin-left: -6px;
    margin-right: 20px !important;
  }
}
@media (max-width: 669px) {
  .scroll-home1 {
    padding: 2px 15px;
  }
}
@media (max-width: 899px) {
  .tabs-align1 {
    width: 100%;
    margin: auto !important;
  }
  .custom-h {
    border-bottom: 1px solid #c3c3c3;
    height: auto;
    padding-bottom: 2rem;
  }
}
@media (min-width: 1010px) and (max-width: 1500px) {
  .indexpage {
    width: 68vw;
  }
}
@media (min-width: 669px) and (max-width: 1009px) {
  .indexpage {
    width: 66vw;
  }
}
@media (min-width: 550px) and (max-width: 668px) {
  .indexpage {
    width: 58vw;
  }
  .scroll-home1 {
    padding: 2px 6px;
  }
  .alignheading {
    padding: 0px 13px;
  }
}
@media (max-width: 549px) {
  .indexpage {
    width: 97vw;
  }
  .alignheading {
    padding: 0px 12px;
  }
  
}
@media (max-width:899px) {
  .padding-list{
    padding: 12px;
  }
}