.container-events {
  width: 63vw;
  text-align: start;
  overflow: auto;
  height: 100vh;
}
.container-events::-webkit-scrollbar {
  display: none;
}
.display-tabs {
  display: flex;
}
.sidenav-icon {
  border-radius: 12px;
  background: #edecf6;
  padding: 42px;
  margin: 23px 39px;
  color: var(--themecolor);
}

.active-tab {
  background-color: red;
}
.tab-content {
  padding: 1rem 0rem;
}
.container-events-data {
  position: relative;
}
.btnprenxt {
  display: flex;
  justify-content: space-between;
  padding: 0px 25px 0px 25px;
}
.btn-prev {
  background-color: red;
}
.btnprenxt button {
  background-color: var(--themecolor);
  font-weight: 800;
}
.video-avtiveBtn Button {
 
  width: 88%;
  border: 2px solid #65bd19 !important;
  color: #65bd19 !important;
  border-radius: 7px;
  padding: 2px 35px !important;
  font-size: 10px;
  font-weight: 800;
}
.video-progress Button {
  border: 2px solid rgb(16, 35, 209);
  border-radius: 7px;
  padding: 2px 35px;
  font-size: 10px;
  color: rgb(16, 35, 209);
  font-weight: 800;
}
.video-InavtiveBtn Button {
  border: 2px solid #00000099;
  border-radius: 7px;
  font-size: 10px;
  color: #000000b5;
  padding: 2px 33px;
  font-weight: 800;
}
.btnprenxt button:hover {
  background-color: var(--themecolor);
}
.tab-content Tabs:active {
  background-color: red;
}
.heading-header h2 {
  font-weight: 550;
  font-size: 22px;
  padding: 11px 0px 13px 32px;
}
.heading-toggletxt {
  font-size: 18px;
}
.btn-toggle {
  background: var(--themecolor);
}
.upload-fileContainer {
  display: flex;
  justify-content: space-between;
}
.container-toggleLive {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0px 0px 0px;
}
.btn-toggle-others {
  margin: 0rem 2rem 0rem 5rem;
}
.heading-events h4 {
  font-size: 20px;
  font-weight: 700;
  text-align: start;
}
.ques-field {
  margin: 0px 0px 12px 0px;

  width: 100%;
}
.container-addQue {
  width: 400px;
  margin: 0px 0px 12px 0px;
}
.btn-Addque {
  margin: 18px 0rem -1rem 0rem;
}
.btn-Addque button {
  background: var(--themecolor);
  color: white;
  font-size: 13px;
  font-weight: 700;
}
.btn-Addque button:hover {
  background: var(--themecolor);
  font-weight: 700;
}
.button-container button {
  text-align: end;
}
.button-container h5 {
  text-align: start;
  font-size: 19px;
  font-weight: 546;
}
.container-others {
  display: flex;
  justify-content: space-between;
  padding: 18px 0px 0px 0px;
  width: 48vw;
}
.music-icon {
  font-size: 74px !important ;
}
.expanticon {
  border: 1px solid var(--themecolor) !important;
  border-radius: 12pc !important;
  font-size: 40px !important;
  text-align: end !important;
  color: var(--themecolor) !important;
}

.upload-button {
  background-color: var(--themecolor) B59CC;
  color: white;
  padding: 4px 9px;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 700;
}
.btn-activeinactive {
  display: flex;
  justify-content: center;
}
.btn-status-video {
  margin: 0px 59px;
}
.expanticon {
  border: 1px solid var(--themecolor) !important;
  border-radius: 12pc;
  font-size: 26px;
  text-align: end !important;
  color: var(--themecolor) !important;
}
.display-status {
  display: flex;
}
.btn-anss Button {
  background-color: var(--themecolor);
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 19px;
}
.btn-anss Button:hover {
  background-color: var(--themecolor);
}
.align-time {
  text-align: end;
  padding: 0px 65px;
}
.heading-quesans h5 {
  font-size: 19px;
  font-weight: 548;
}
.show-tabs{
  border-right: 1px solid lightgrey;
}
.file-name  {
  text-align: start;text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap!important;
}
.iconGhost{
  margin-right: 16px!important;
  margin-left: 36px!important;
}
@media (max-width: 1024px) {
  .container-others {
    display: flex;
    justify-content: space-between;
    padding: 18px 0px 0px 0px;
    width: 58vw;
  }
  .file-name {
    text-align: center;text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap !important;
  }
}
@media (min-width:621px) and (max-width: 757px) {
  .container-events {
    width: 65vw;
  }
}
@media (max-width: 620px) {
  .container-events {
    width: 96vw;
  }
}

@media (max-width: 420px) {
  .music-icon {
    font-size: 52px !important;
    margin-left: -15px;
}
}
@media (max-width: 1024px) {
  .tabspanel {
    width: 19vw !important;
  }
}
@media (max-width: 768px) {
  .tabspanel {
    width: 35vw;
  }
}
@media (max-width: 656px) {
  .tabspanel {
    width: 190px;
  }
}
@media (max-width: 620px) {
  .show-tabs {
    display: none;
  }
}
.jgugf {
  background-color: red;
}
.tab-container {
  overflow-y: hidden !important;
}

@media only screen and (min-width: 1024px) and (max-width: 1214px) {
  .video-progress Button {
    border: 2px solid rgb(16, 35, 209);
    border-radius: 7px;
    padding: 2px 20px;
    font-size: 10px;
    color: rgb(16, 35, 209);
    font-weight: 800;
  }
}

@media only screen and (min-width: 615px) and (max-width: 768px) {
  .video-avtiveBtn Button {
    border: 2px solid #65bd19;
    color: #65bd19;
    padding: 2px 30px;
    font-size: 11px;
    width: 88%;
    margin: 3px 2px;
}
  .video-InavtiveBtn Button {
    padding: 2px 28px;
    font-size: 11px;
    width: 95%;
    margin: 3px 0px;
}
  .upload-button {
    padding: 4px 5px;
  }
}

@media only screen and (min-width: 615px) and (max-width: 849px) {
  .tabspanel {
    width: 35vw !important;
  }
}

@media only screen and (min-width: 850px) and (max-width: 922px) {
  .tabspanel {
    width: 29vw !important;
  }
  .container-events {
    width: 69vw;
  }
}

@media only screen and (min-width: 750px) and (max-width: 802px) {
  .video-avtiveBtn Button {
    width: 100%;
  }
  .video-InavtiveBtn {
    border-radius: 7px;
    padding: 2px 0px !important;
    font-size: 10px;

    font-weight: 800;
  }.videoname {
    padding-left: 20px;
}
}
@media only screen and (min-width: 320px) and (max-width: 513px) {
  .video-avtiveBtn Button {
    border: 2px solid #65bd19;
    color: #65bd19;

    padding: 2px 30px;
    font-size: 10px;
    margin: 3px 23px;

    font-weight: 800;
  }
  .video-InavtiveBtn {
    padding: 2px 30px;
    font-size: 10px;
    margin: 3px 23px;

    font-weight: 800;
  }
  .btn-activeinactive {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 0px 11px;
  }
  .expanticon {
    border: 1px solid var(--themecolor) !important;
    border-radius: 12pc !important;
    font-size: 33px;
    text-align: end !important;
    margin-left: -3rem;
    color: var(--themecolor) !important;
  }
  .video-avtiveBtn Button {
    width: 74%;
  }
  .video-InavtiveBtn {
    width: 94%;
    padding: 3px 21px;
    margin: 2px -28px;
  }
}
.btnerror:hover{
  background-color: #6b59cc;
  color: white;
  font-weight: bold
}
.iconGhost1 {
  margin-right: 16px;
  margin-left: 37px;
  margin-right: 16px!important;
}

@media only screen and (min-width: 829px) and (max-width: 1023px) {
  .container-events {
    width: 68vw;
    text-align: start;
  }
  .video-avtiveBtn Button {
    margin: 0px 4px;
  }
}
@media only screen and (min-width: 759px) and (max-width: 828px) {
  .container-events {
    width: 63vw;
    text-align: start;
  }
}
@media (max-width: 751px) {
  .display-status {
    display: flex;
    flex-direction: column;
    margin: 0px 12px;
  }
}

@media (max-width: 375px) {
  .btn-Addque button {
    font-size: 10px;
    padding: 2px 8px;
  }
  .align-time {
    text-align: end;
    padding: 0px 43px;
  }
}
@media (max-width: 486px) {
  .container-addQue {
    width: 74%;
    margin: 0px 0px 12px 0px;
  }
}
@media (max-width: 620px) {
}
@media (min-width: 832px) and (max-width: 940px) {
  .video-InavtiveBtn Button {
    padding: 2px 33px;
    font-weight: 800;
  }
}
@media (min-width: 758px) and (max-width: 831px) {
  .video-progress Button {
    margin: 0px 21px;
    padding: 2px 16px;
    font-size: 10px;
    width: 100%;
  }
  .upload-button {
    padding: 6px 9px;

}
}
@media (min-width:800px) and (max-width:845px) {
  .music-icon {
    font-size: 66px !important;
}
}
@media (min-width: 666px) and (max-width: 757px) {
  .video-progress Button {
    margin: 0px 21px;
    padding: 3px 46px;
    font-size: 11px;
  }
}

@media (min-width: 613px) and (max-width: 665px) {
  .video-progress Button {
    margin: 0px 23px;
    padding: 2px 35px;
    font-size: 10px;
  }
}
@media (min-width: 510px) and (max-width: 612px) {
  .video-InavtiveBtn{
    margin:auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .video-InavtiveBtn Button {
    text-align: center;
    width: 80%;
    margin: 0px 16px;
  }
}

@media (min-width: 475px) and (max-width: 509px) {
  .video-InavtiveBtn{
    margin:auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .video-InavtiveBtn Button {
    border: 2px solid #00000099 !important;
    color: #000000b5 !important;
    width: 76%;
    padding: 0px 17px;
    margin: -9px 49px;
    font-size: 12px;
  }
}
@media (min-width: 320px) and (max-width: 475px) {
  .video-InavtiveBtn Button {
    margin: auto;
    display: flex;

    font-size: 12px;
    padding: 2px 30px;
    width: 71%;
  }
}
@media (min-width: 837px) and (max-width: 899px) {
  .video-avtiveBtn Button {
    margin: 0px 0px;
    width: 82%;
  }
}
@media (min-width: 393px) and (max-width: 599px) {
  .video-InavtiveBtn{
    margin:auto;
   
  }
  .video-avtiveBtn Button {
    padding: 2px 30px;
    width: 69%;
    margin: 0px 3rem;
  }
  .video-progress Button {
    border: 2px solid rgb(16, 35, 209);
    border-radius: 7px;
    margin: 0px 3rem;
    padding: 2px 35px;
    font-size: 10px;
    color: rgb(16, 35, 209);
    font-weight: 800;
    width: 80%;
    margin: auto;
    display: flex;
  }
}

@media (min-width: 320px) and (max-width: 391px) {
  .video-avtiveBtn Button {
    border: 2px solid green;
    border-radius: 7px;
    padding: 2px 30px;
    font-size: 10px;
    width: 71%;
    color: black;
    font-weight: 800;
  }
  .video-progress Button {
    border: 2px solid rgb(16, 35, 209);
    border-radius: 7px;
    margin: 0px 1rem;
    padding: 2px 35px;
    font-size: 10px;
    color: rgb(16, 35, 209);
    font-weight: 800;
    width: 71%;
  }
}

@media (min-width: 1024px) and (max-width: 1185px) {
  .video-InavtiveBtn Button {
    border: 2px solid #00000099;
    border-radius: 7px;
    padding: 2px 22px !important;
    font-size: 10px;
    color: #000000b5;
    padding: 2px 33px;
    font-weight: 800;
  }
}
@media (min-width:990px) and (max-width:1130px) {
  .container-events {
    width: 63vw;
    text-align: start;
}
}
@media (min-width:900px) and (max-width:987px) {
  .upload-button {
    font-size: 10px;
   padding: 4px 4px;
    width: 100%;
}
}
@media (min-width:421px) and (max-width:564px) {
   .file-name{
    padding-left: 34px;
   }
}

@media (min-width:222px) and (max-width:429px) {
  .video-avtiveBtn Button {
    width: 98%;
  margin: 0px 15px 0px 0px;
  }

}

@media (min-width:1411px) and (max-width:1593px) {
  .iconGhost {
    margin-right: 16px!important;
    margin-left: 1.8rem!important;
}
}
@media (min-width:923px) and (max-width:1024px) {
  .iconGhost {
    margin-right: 16px!important;
    margin-left: 2px!important;
  }
  .iconGhost1 {
    margin-right: 16px!important;
    margin-left: 2px!important;
  }
}



  @media (max-width:1920px) {
    .container-events{
      height: 92vh;
    }
  }


