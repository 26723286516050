.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}
.height-cht {
  height: 87.4vh;
}
.height-parti {
  height: 83.7vh;
}
.ico-send{
  font-size: 30px!important;
}
.main-box {
  flex: 1;
  padding-bottom: 3rem;
  overflow-y: auto !important;
}
.p-participant{
  padding:16px;
}
.main-box::-webkit-scrollbar {
  display: none !important;
}
.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 867px;
  height: 87.7vh;
  border-radius: 5px;
}
.list-message {
  list-style: none;
  padding: 0;
  width: 100%;
  margin:auto!important;
}
.list-item-text-message {
  background-color: var(--themecolor);
  color: white;
  padding: 10px;
  border-radius: 0 22px 0 25px;
  word-break: break-word;
  width: 100px;
}
.height-cht{
  height: 83vh;
}
.field-box {
  background-color: rgba(255, 255, 255, 0.893);
  height: 38px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.main-box-video {
  overflow: auto;
}
.send-button Button{
  width: 100%;
  right: 0;
  bottom: 0;
  margin-right: 28px;
  background: none!important;
  box-shadow: none!important;
  color: var(--textcolor);
  border-radius: 0px 13px 13px 0px;
  z-index: 9999;
  padding: 14px 11px;
}
.cicular_loader{
  width: 40px;
    height: 40px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50px, 50px);
}
.chat-container {
  position: relative;
  height: 508px;
  background-color: #f5f3f0;
}

.chat-text {
  background: white;
  max-height: 38px;
  overflow-y: auto;
  border: none !important;
}

.chat-text::-webkit-scrollbar {
  display: none;
}
.container-field {
  background-color: white;
  z-index: 9999;
}
.container-webinarview {
  background-color: white;
  height: 93vh!important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden!important;
}
.video-div {
  position: relative;
  background-color: black !important;
}
.height-parti{
  height: 84vh;
 
}
.volume-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  z-index: 999;
}

.container-chatsview {
  height: 83vh;
}

.volume-txt h5 {
  font-size: 16px;
  font-weight: bolder;
}

.particepent-container {
  overflow-y: auto;
}

.particepent-container::-webkit-scrollbar {
  display: none;
}
.m-chats {
  width: 50%;
}

.participant-tab {
  width: 50%;
}

.bg-btncolor Button {
  background-color: var(--themecolor);
  font-size: 15px;
  font-weight: bold;
}

.bg-btncolor Button:hover {
  background-color: var(--themecolor);
  font-size: 15px;
  font-weight: bold;
}
.container-joinform {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border-radius: 17px !important;
  padding: 6rem 3rem !important;
  width: 450px;
}
@media (max-width: 1500px) {
  .container-webinarview {
    background-color: white;
    height: 89vh;
  }
  .video-div {
    position: relative;
    background-color: black;
  }

  .particepent-container {
    overflow-y: auto;
    height: 73.5vh;
  }

  .container-chatsview {
    height: 82vh;
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
  .video-div {
    width: 100%;
    position: relative;
    overflow-y: hidden;
  }

  .break-word {
    word-break: break-word;
  }

  .message-item {
    margin-bottom: 10px;
  }
}

@media only screen and (min-device-width: 1025px) and (max-device-width: 1740px) {
  .video-div {
    width: 100%;
    position: relative;
    overflow-y: hidden;
  }

  .break-word {
    word-break: break-word;
  }

  .message-item {
    margin-bottom: 10px;
  }

  .container-field {
    /* width: 28vw; */
  }
}
@media (min-width:2000px) {
  .height-parti {
    height: 88vh;
}
.height-cht {
  height: 90vh;
}
}
@media only screen and (min-device-width: 482px) and (max-device-width: 616px) {
  .video-div {
    height: 50vh;
  }
}

@media only screen and (min-device-width: 482px) and (max-device-width: 616px) {
  .container-webinarview {
    height: 60vh;
  }

  .video-div {
    height: 60vh;
  }
}

@media only screen and (min-device-width: 617px) and (max-device-width: 923px) {
  .video-div {
    height: 60vh;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1263px) {
  .video-div {
    position: relative;
    height: 78vh;
  }
}

@media (max-width: 923px) {
  .list-message {
    width: auto;
  }
}

/* @media (max-width: 973px) {
  .list-message {
    width: 70%;
  }
} */

@media (max-width: 573px) {
  .list-message {
    width:100%;
  }
}

@media (min-width: 478px) and (max-width: 1024px) {
  /* .send-button {
    position: absolute;
    bottom: 2;
    font-size: 11px;
    width: 52px;
  } */

  .container-field {
    background-color: white;

    z-index: 9999;
  }

  .chat-container {
    position: relative;
    height: 362px;
    background-color: #f5f3f0;
  }
}

@media (min-width: 1025px) and (max-width: 1124px) {
  .chat-container {
    height: 388px;
  }
}

@media (min-width: 1125px) and (max-width: 1244px) {
  .chat-container {
    position: relative;
    height: 424px;
    background-color: #f5f3f0;
  }
}

@media (min-width: 1245px) {
  .chat-container {
    position: relative;
    height: 492px;
  }
  .participant-tab{
    font-size: 13px;
  }
}
@media (min-width: 900px) and (max-width: 1184px) {
  .participant-tab,
  .m-chats {
    font-size: 12px; /* Adjust this to your desired font size for smaller screens */
  }
  .send-button {
    right: 0;
    bottom: 0;


    z-index: 9999;

}
}
@media (min-width: 1185px) and (max-width: 1400px)  {
  .send-button {
    right: 0;
    bottom: 0;
    margin-right: -7px;
    z-index: 9999;
    /* padding: 10px 0px; */
}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .video-div {
    position: relative;
    height: 296px;
  }

  .container-webinarview {
    height: auto!important;
  }

  .main-container {
    height: "85vh";
    overflow-y: hidden;
  }

  .break-word {
    word-break: break-word;
  }

  .message-item {
    margin-bottom: 10px;
  }

  /* .container-field {
    width: 80vw;
  } */
}

@media only screen and (min-device-width: 899px) and (max-device-width: 1081px) {
  .m-chats {
    margin-left: 12px !important;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 548px) {
  .container-joinform {
    width: 63%;
  }
}

@media only screen and (max-width: 500px) {
  .g-recaptcha {
    transform: scale(0.77);
    transform-origin: 0 0;
  }

  .box-form {
    width: 62%;
  }

  .box-form-signup {
    width: 70%;
    padding: 6rem 1.5rem !important;
  }
}

.container-webinarview {
  height: 100vh;
}

.ssss {
  overflow: hidden;
}

.particepent-container {
  overflow-y: auto;
}


















.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);

  color: #666;
}


.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  height: calc(100% - 50px);
  scrollbar-width: thin;  /* For Firefox */
  scrollbar-color: #888 #f5f5f5;  /* For Firefox */

  /* For WebKit browsers like Chrome and Safari */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3d2c97b3;
    border-radius: 21px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
}

.join-chat{

    width: fit-content;
    background: #cdcacaa3;
    color: white;
    font-weight: 500;
    padding: 8px 24px;
    font-size: 14px;
    border-radius: 6px;
    margin: 0px auto 14px auto;
    text-align: center;
}

.videoReact::-webkit-media-controls {
  display: none!important;
}

@media (max-width:899px) {
  .container-webinarview {
    background-color: white;
    height: auto!important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden!important;
}

}

@media (min-width:1183px) and (max-width:1509px) {
  .send-button Button {
    margin-right:15px;
  }
}
@media (max-width:1024px) {
  .p-participant{
    padding:2px;
  }
  .send-button Button{
    padding:17px 11px
  }
  .ico-send{
    font-size: 25px !important;
  }
}
@media (max-width:500px) {
  .send-button Button {
    padding: 16px 11px;
}
.ico-send {
  font-size: 27px !important;
}
}
@media (min-width:1010px) and (max-width:1182px) {
.send-button Button {
 
  margin-right: 3px;}

}
@media (min-width:900px) and (max-width:1009px)  {
  .send-button Button {
  
    margin-right: -4px;

}
}
@media (min-width:721px) and (max-width:899px) {
  .send-button Button{
    margin-right: 18px;
  }
}
@media (min-width:600px) and (max-width:721px) {
  .send-button Button{
    margin-right:0px
  }
}
@media (min-width:425px) and (max-width:599px) {
.send-button Button {
  margin-right: 37px;
}
}
@media  (min-width:320px) and (max-width:424px) {
  .send-button Button {
     margin-right: 16px;
}
}

@media (min-width:206px) and (max-width:319px) {
  .send-button Button {
    margin-right: -7px;
}
}

@media (min-width:2740px) and (max-width:3576px) {
  .send-button Button {
    margin-right: 4rem;
}
}
@media (max-width:1980px) {
  .height-cht {
    height: 87.4vh;
}
}
@media (max-width:1817px) {
  .height-parti {
    height: 85.7vh;
}

.height-cht {
  height: 88.4vh;
}
}

@media (max-width:1440px) {
  .container-webinarview{
    height: 92vh!important;;
  }
  .height-parti{
    height: 81.88vh;
  }
  .height-cht {
    height: 85.66vh;
}
}

@media (max-width:899px) {
  .container-webinarview{
    height:auto!important;
  }
}
@media (max-width:1600px) {
  .height-cht {
    height: 86.4vh;
}
.container-webinarview{
  height:92vh!important;
}
.height-parti{
  height: 83.7vh;
}
}
@media (max-width:1536px) {
  .container-webinarview{
    height: 92vh!important;
  }
  .height-parti {
    height: 82.9vh;
}
.height-cht{
  height:86.3vh;
}
}
@media (max-width:1366px) {
  .container-webinarview {
    height: 91vh!important;
}
.height-parti{
  height:80.9vh;
}
.height-cht {
  height: 85vh;
}
}

@media (max-width:1280px) {
  .container-webinarview{
    height:83vh!important;
  }
  .height-parti {
    height: 79.58vh;
}
  .height-cht {
    height: 85vh;
}
}
@media (max-width:412px) {
  .container-webinarview{
    height: auto!important;
  }
}

@media (max-width:962px) {
  .container-webinarview {
    height: 89vh!important;
}

.height-parti {
  height: 78vh;
}


.height-cht {
    height: 81vh;
}
}

@media (max-width:1243px) {
  .height-parti{
    height:80vh;
  }
  .height-cht {
    height: 84vh;
}
}

@media (max-width:899px) {
  .container-webinarview{
    height: auto!important;
  }
}


