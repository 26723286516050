.logo {
  width: 200px;
}
.logo-small {
  width: 150px !important;
}
.nav-btn:hover {
  background-color: #544991 !important;
}
.showbtn1{
  display:block;
}
.showbtn2{
  display:none;  
}
.leaveconfirm button{
  background-color: var(--themecolor);
    font-size: 13px;
    font-weight: bold;
    color: white;
}
.leaveconfirm button:hover{
  background-color: var(--themecolor);
}
.leavecancel button{   background: #d3d3d36b;
font-size: 14px;
color: grey;

}
.leavecancel button:hover{   background: #d3d3d36b;
  font-size: 14px;
  color: grey;
  
  }
@media (min-width:1100px) and (max-width:1400px) {
  .m-leave{
    margin-right:6rem;
  }
}
@media (min-width:450px) and (max-width:1099px) {
  .m-leave{
    margin-left: -8rem;
  }
}

@media  (max-width:449px) {
  .showbtn1{
    display:none;
  }
  .showbtn2{
    display:block;  
  }
}