@media (min-width: 320px) and (max-width: 425px) {
  .landing-page {
    font-size: 13px;
  }
  img {
    width: 180px;
  }
}
@media (min-width: 430px) and (max-width: 768px) {
  .landing-page {
    font-size: 34px;
  }
}
@media (min-width: 770px) and (max-width: 2560px) {
  .landing-page {
    font-size: 47px;
  }
}
.landing-page {
  width: 100vw;
  height: 98vh;
  background-color: white;
  color: #6b59cc;
  display: "flex";
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 320px) and (max-width: 425px) {
  .thank-you {
    font-size: 13px;
  }
  img {
    width: 180px;
  }
}
@media (min-width: 430px) and (max-width: 768px) {
  .thank-you {
    font-size: 34px;
  }
}
@media (min-width: 770px) and (max-width: 2560px) {
  .thank-you {
    font-size: 47px;
  }
}
.thank-you {
  width: 100vw;
  height: 100vh;
  color: white;
  display: "flex";
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #6b59cc;
}
