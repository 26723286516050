.div-table {
  padding: 20px;
}
.head-div {
  border: 2px solid yellow;
}
.tablecontainer {
  margin-top: 2rem!important;
}
.table-cell {
  background-color: #6b59cc;
}
.tablecontainer::-webkit-scrollbar {
  display: none;
}
.container-analytics {
  height: 89vh;
  overflow-y: auto;
}
.container-analytics::-webkit-scrollbar {
  display: none;
}
.container-chatReport{
  height: auto!important;
  overflow-y: auto;
}
.container-chatReport::-webkit-scrollbar {
    display: none;
  }
.heading-chatreport h4{
    font-size: 22px;
    font-weight: 800;
}
.table-celltr1 {
  overflow-wrap: anywhere!important;
}
.tablechatreport{
  width: 88vw!important;
  margin-top: 2rem!important;
}
@media (min-width: 900px) and (max-width: 1200px) {
  .tablechatreport{ width: 84vw!important;}
}
@media (min-width:100px) and (max-width:899px){
  .tablechatreport {
    width: 91vw!important;
}
}
@media (min-width: 320px) and (max-width: 425px) {
  .tablecontainer {
    width: auto;
  }
  .table-cell {
    width: 20px;
  }
}

