.div-table {
  padding: 20px;
}
.head-div {
  border: 2px solid yellow;
}
.nprmalusercontainer {
  width: 94vw!important; 
}
.tablecontainer {
  margin-top: 10px;
  height:  77vh
}
.table-cell {
  background-color: #6b59cc;
}
.tablecontainer::-webkit-scrollbar {
  display: none;
}

.container-analytics {
  height: 89vh;
  overflow-y: auto;
}

.container-analytics::-webkit-scrollbar {
  display: none;
}

.container-chatReport {
  height: 79vh;
  overflow-y: auto;
}

.container-chatReport::-webkit-scrollbar {
  display: none;
}

.heading-chatreport h4 {
  font-size: 22px;
  font-weight: 800;
}

.gridPadding {
  padding: 32px;
}

.ModalUser {
  width: 424px;
}

.btnCreate .createUserBtn {
  background: #6b59cc !important;
  font-weight: 700;
  font-size: 15px;
  padding: 9px 0px;
  width: 100%;
}

.btnUpdate {
  background: #6b59cc !important;
  color: white !important;
}

.btnCreate .createUserBtn:hover {
  background: #6b59cc;
}

.searchUser {
  margin-right: 13px;
}

.createUserBtn:hover {
  background-color: #6b59cc !important;
  font-weight: 800;
}

.noramuserHeading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.modal-normalUser{

}
.modal-normalUser::-webkit-scrollbar {
  display: none;
}

.modal-normalUser{
-ms-overflow-style: none;  
scrollbar-width: none; 
}
@media (min-width:900px) and (max-width:1200px) {
  .nprmalusercontainer {
    width: 91vw!important;
}
}
@media (min-width:426px) and (max-width:899px) {
  .nprmalusercontainer {
    width: 99.6vw!important;
    margin-top: 2rem;
}
}
@media (min-width: 220px) and (max-width: 425px) {
  .tablecontainer {
    width: auto;
  }
  .nprmalusercontainer {
    width: 100vw!important;
}
  .table-cell {
    width: 20px;
  }

  .modal-normalUser {
    width: 238px;
  }
}

@media (min-width: 650px) and (max-width: 768px) {
  .createUserBtn {
    width: 269px !important;
  }
}

@media (min-width: 200px) and (max-width: 649px) {
  .createUserBtn {
    font-size: 10px !important;
  }

  .heading-chatreport h4 {
    margin-bottom: 1rem;
    font-size: 17px;
    font-weight: 800;
  }

  .gridPadding {
    padding: 16px;
  }
}

@media (max-width: 507px) {
  .noramuserHeading {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 907px) {
  .nprmalusercontainer {
    width: 100vw;
  }
}

@media (max-width:1920px) {
  .tablecontainer {
    margin-top: 10px;
    height: 74vh;
}
}
@media (max-width:1366px) {
  .tablecontainer {
    margin-top: 10px;
    height: 59vh;
}
.container-events {
  height: 91vh;
}
}

@media (max-width:1280px) {
  .tablecontainer{
    height:58vh;
    margin-bottom: 1rem;
  }
}

@media (max-width:1536px) {
  .tablecontainer {
    margin-top: 10px;
    height: 66vh;
}
}